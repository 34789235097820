import * as React from "react"
import withLayout from "../hoc/withLayout";
import { CityPageHeader } from "../components/CityPageHeader";
import GrodnoImg from "../images/gorod-grodno-lg.jpg";
import GrodnoImgWebp from "../images/gorod-grodno-lg.jpg.webp";
import GrodnoMapImg from "../images/gorod-grodno-map.png";
import GrodnoMapImgWebp from "../images/gorod-grodno-map.png.webp";

import IndexPrice from "../components/index/price/Price";
import { CityPageDelivery } from "../components/CityPageDelivery";
import HelpPhone from "../components/HelpPhone";
import Ecology from "../components/Ecology";
import Map from "../components/Map";
import { CityPageAboutUs } from "../components/CityPageAboutUs";



const Grodno = () => {
  return (
    <>
      <CityPageHeader 
        header={<h1>Чистка сажевого фильтра в Гродно <small>и Гродненской области</small></h1>} 
        jpg={GrodnoImg} 
        webp={GrodnoImgWebp} 
        alt="Чистка сажевого фильтра в Гродно и Гродненской области"
      />

      <IndexPrice/>
      
      <CityPageDelivery 
        from="Гродно и Гродненской области" 
        jpg={GrodnoMapImg}
        webp={GrodnoMapImgWebp}
        alt="Доставка сажевых фильтров в Гродно и Гродненскую область"
      />

      <HelpPhone/>
      <CityPageAboutUs/>
      <Ecology/>
      <Map/>
    </>
  )
}

export default withLayout(
  Grodno, 
  "Чистка сажевых фильтров в Гродно",
  "Удаление 99% загрязнений возникающих из сажи, пепла, масла из двигателя и охлаждающей жидкости, без повреждения картриджа фильтра");
